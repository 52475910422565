<template>
  <div class="pre-footer">
    <PreFooterNewsletter />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import PreFooterNewsletter from '~/components/PreFooterNewsletter.vue';

export default defineComponent({
  name: 'PreFooter',
  components: {
    PreFooterNewsletter,
  },
  props: {},
  setup() {
    return {};
  },
});
</script>

<style lang="scss" scoped>
.pre-footer {
  max-width: var(--desktop-min);
  margin-top: var(--spacer-2xl);
  margin-right: auto;
  margin-left: auto;
  &__services {
    width: 80vw;
    height: 115px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: var(--spacer-2xl) auto 0 auto;
    &:before {
      content: '';
      position: absolute;
      left: -5vw;
      top: 0;
      height: 1px;
      width: 90vw;
      border-top: 1px solid lightgrey;
    }
  }
}

@media (max-width: 768px) {
  .pre-footer {
    &__services {
      gap: 5px;
      &:before {
        width: 80vw;
        left: 0;
      }
    }
  }
}
</style>
